import "./styles.css";
import React, {
  useRef,
  useState,
  useLayoutEffect,
  useEffect,
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useContext
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDropzone } from "react-dropzone";
import Canvas3D from "./Canvas3D";
import { useDebounceEffect } from "./UseDebounceEffect";
import { canvasPreview } from "./PreProcess";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import Layout from "../../layout/layout"
import {
  Grid,
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TableContainer,
  Modal,
  CircularProgress,
  TextField,
  InputAdornment,
  ListSubheader,
  Tooltip,
} from "@mui/material";
import productData from "../../productDatas";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import RemoveCircleTwoToneIcon from "@mui/icons-material/RemoveCircleTwoTone";
import SentimentVerySatisfiedTwoToneIcon from "@mui/icons-material/SentimentVerySatisfiedTwoTone";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from "@mui/icons-material/Search";
import SwipeLeftTwoToneIcon from "@mui/icons-material/SwipeLeftTwoTone";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import VisibilityOffTwoToneIcon from "@mui/icons-material/VisibilityOffTwoTone";
import { useGetProductService } from "../../services/productService";
import BrandingSelection from "./BrandingSelection/BrandingSelection";
import ApiLinks from "../../api/apis"
import LogoBtn from "../../assets/logo-btn.png"

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFA100",
    },
  },
});

function centerAspectCrop(mediaWidth, mediaHeight) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 100,
      },
      1,
      512,
      512
    ),
    512,
    512
  );
}
let run1 = sessionStorage.getItem("run1");
const containsText = (text, searchText) =>
  text.toLowerCase().includes(searchText.toLowerCase());
const filterProducts = (product, filter) => product.type === filter;

const ProductSelection = () => {
  const { sku } = useParams();
  const snapshotRef = useRef();
  const [productCode, setProductCode] = useState(0);
  const [productSummary, setProductSummary] = useState("");
  const [productName, setProductName] = useState("");
  const [defaultColor, setDefaultColor] = useState("");
  const [productColor, setProductColor] = useState("");
  const [colorList, setColorList] = useState([]);
  const [sceneList, setSceneList] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(null);
  const [showPrompt, setShowPrompt] = useState(true);
  const [showOverlay, setShowOverlay] = useState(true);
  const [overlayLoader, setOverlayLoader] = useState(false);

  const inputRef = useRef(null);
  const navigate = useNavigate()


  // brand selection
  const [currentIndex, setCurrentIndex] = useState("");
  const [currentProcess, setCurrentProcess] = useState("");
  const [imgSrc, setImgSrc] = useState("");
  const [brandingOptions, setBrandingOptions] = useState([]);
  const [locationsBranding, setLocationsBranding] = useState([]);
  const [open, setOpen] = useState(false);
  const [showProductBuild, setProductBuild] = useState(false);
  const [showBrandSelection, setBrandSelection] = useState(false);


  //pre-processing

  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [scale, setScale] = useState(1);
  const [rotate] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const [currentFileName, setCurrentFileName] = useState("");
  const [isMultiple, setIsMultiple] = useState(false);
  const [isLonger, setIsLonger] = useState(null);
  const [showToggle, setShowToggle] = useState(false);
  const [filterVal, setFilterVal] = useState("");
  const [searchText, setSearchText] = useState("");
  // const [{ data: productData = [] }] = useGetProductService();



  const locationOptions = locationsBranding?.map(({ location }) => location.toUpperCase()) || []
  const filteredLocationOptions = locationOptions.filter((location) => {
    if (location === "SIDE OF BARREL" && brandingOptions.find(option => option.location.toUpperCase() === "ENLARGED AREA SIDE OF BARREL")) {
      return false
    }
    if (location === "ENLARGED AREA SIDE OF BARREL" && brandingOptions.find(option => option.location.toUpperCase() === "SIDE OF BARREL")) {
      return false
    }
    return true
  }) || []


  function onImageLoad(event) {
    setCrop(centerAspectCrop(512, 512));
    const img = document.querySelector("#img-processed");
    if (img.naturalHeight < img.naturalWidth) {
      setIsLonger(false);
    } else {
      setIsLonger(true);
    }
  }

  //Display the selected option
  const displayedOptions = useMemo(
    () =>
      productData?.filter((option) =>
        containsText(
          option.productCode + " - " + option.productName,
          searchText
        )
      ),
    [productData, searchText]
  );

  const loadBottle = () => {
    Swal.fire({
      title: "Rendering your product!",
      text: "Loading product textures...",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      customClass: {
        loader: "custom-loader",
      },
      timer: 6200,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        let dataUrl = canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );

        dataUrl.then((data) => {
          srcToFile(data, currentFileName, "image/png").then(function (
            file
          ) { });
        });
      }
    },
    100,
    [completedCrop, scale, rotate]
  );
  const handleOpenModal = (process, rowId) => {
    setCurrentProcess(process);
    setCurrentIndex(rowId);
    setOpen(true);
  };
  const handleCloseModal = () => {
    setShowToggle(false);
    setShowLoading(false);
    setShowEdit(false);
    setCurrentIndex("");
    setImgSrc("");
    setOpen(false);
  };

  const handleOpenMultipleModal = () => {
    sessionStorage.setItem("openedAll", true);
    brandingOptions.every((branding) => {
      if (branding.process === "SILK SCREENED") {
        setCurrentProcess("SILK SCREENED");
        return false;
      } else {
        setCurrentProcess("");
      }
    });
    setIsMultiple(true);
    setOpen(true);
  };

  function removeHTMLTags(text) {
    return text.replace(/<[^>]+>/g, " ");
  }

  const initializeOptions = () => {
    const selectedProduct = JSON.parse(sessionStorage.getItem("product"));

    if (!selectedProduct) {
      return;
    }

    const locationsBranding = selectedProduct.locationsBranding.flat()
    setLocationsBranding(locationsBranding)
    setBrandingOptions([
      {
        id: Date.now().toString(),
        location: locationsBranding[0].location.toUpperCase(),
        process: locationsBranding[0].brandings[0].toUpperCase(),
        logoName: "APPLY ART",
        isRectangle: "not"
      },
    ]);
  };

  const onMenuItemChange = (product) => {
    setProductName(product.productName);
    const htmlString = product.productSummary;
    const clean = removeHTMLTags(htmlString);
    sessionStorage.setItem("productSummary", clean);
    setProductSummary(sessionStorage.getItem("productSummary"));
    setDefaultColor(product.defaultColor);
    sessionStorage.setItem("productCode", product.productCode);
    sessionStorage.setItem("product", JSON.stringify(product));
    sessionStorage.setItem("productColor", product.defaultColor);
    setProductBuild(true);
    setProductColor(product.defaultColor);
    sessionStorage.removeItem("showStrap");
    let productCode = product.productCode;
    let productDetails = productData?.find(
      (product) => product.productCode === productCode
    );
    setColorList(productDetails.colorsAvailable);

    let newState = [];
    productDetails.colorsAvailable.forEach((product) => {
      let temp = {};
      temp["productColor"] = product.code;
      temp["scene"] = (
        <Canvas3D
          id="canvas-scene"
          ref={snapshotRef}
          productCode={sessionStorage.getItem("productCode")}
          productColor={product.code}
        />
      );
      newState.push(temp);
    });
    setSceneList(newState);
    if (product.defaultColor !== "soldout") {
      setBrandSelection(true);
    }
    setOpenTooltip(null);
  };
  const onProductChange = (event) => {
    setShowOverlay(true);
    initializeOptions();
    loadBottle();
    setProductCode(event.target.value);
    setTimeout(() => {
      setShowOverlay(false);
    }, 2000);

    setBrandSelection(true);
    sessionStorage.removeItem("brandingOptions");
    sessionStorage.removeItem("showStrap");
    if (sessionStorage.getItem("productCode") === "BG116") {
      if (sessionStorage.getItem("productColor") === "GREY") {
        console.log('INSIDE THE IF TESTING')
      }
    }
  };
  useEffect(() => {
    initializeOptions();
  }, []);

  const onProductColorChange = (event) => {
    setShowOverlay(true);
    setOverlayLoader(true);
    loadBottle();
    setTimeout(() => {
      setShowOverlay(false);
      setOverlayLoader(false);
    }, 5500)

    setProductColor(event.target.value);
    sessionStorage.setItem("productColor", event.target.value);
    setBrandSelection(true);

  };
  const onClickNext = () => {
    sessionStorage.setItem(
      "selectedBrandingOptions",
      JSON.stringify(brandingOptions)
    );
    let brandingLocs = [];
    brandingOptions.forEach((branding) => {
      brandingLocs.push(branding.location);
    });
    if (brandingOptions.some((branding) => branding.logoName === "APPLY ART")) {
      Swal.fire({
        title: "We can’t Dec this merch out without artwork!",
        text: "Please upload your artwork and we’ll try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
    else if (brandingLocs.includes("SIDE OF BARREL") &&
      brandingLocs.includes("BESIDE CLIP")) {
      Swal.fire({
        title: "Sorry!",
        text: "But side of barrel can’t be paired with beside clip.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
    else if (brandingLocs.includes("SIDE OF BARREL") &&
      brandingLocs.includes("ENLARGED AREA SIDE OF BARREL")) {
      Swal.fire({
        title: "Sorry!",
        text: "But side of barrel can’t be paired with Enlarged area side of Barrel.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
    else if (brandingLocs.includes("BESIDE CLIP") &&
      brandingLocs.includes("ENLARGED AREA SIDE OF BARREL")) {
      Swal.fire({
        title: "Sorry!",
        text: "But Beside Clip can’t be paired with Enlarged area side of Barrel.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
    else {
      fetch(`${ApiLinks.ActiveLink()}load_images`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          options: brandingOptions,
          productCode: sessionStorage.getItem("productCode"),
          productColor: sessionStorage.getItem("productColor"),
        }),
      })
        .then((res) => res.json())

        .then((data) => {
          sessionStorage.setItem("brandingOptions", JSON.stringify(data));
          fetch(`${ApiLinks.AnalyticsLink()}analytics`, {
            method: "POST",
            headers: {
              "Content-Type": "text/json",
            },
            body: JSON.stringify({
              options: brandingOptions,
              productCode: sessionStorage.getItem("productCode"),
              productColor: sessionStorage.getItem("productColor"),
            }),
          }).then((res) => res.json);

          window.location.href = "/brand-designer";
        });
    }
  };
  // const onClickQuoteForm = () => {
  //   Swal.close();
  //   navigate(`/quote/${productCode}`)
  // }
  const handleLocationChange = (event, rowId) => {
    const { name, value } = event.target;
    setBrandingOptions((prevOptions) => {
      const options = [...prevOptions];
      const row = options.find(({ id }) => rowId === id);
      if (!row) return options

      row[name] = value

      if (name === "location") {
        const processOptions = locationsBranding.find(({ location }) => location.toUpperCase() === value.toUpperCase())?.brandings || []
        row.process = processOptions[0].toUpperCase()
      }
      let copyProcess = row.process;
      if (row.logoName === "APPLY ART") return options

      if (row.process === "SILK SCREENED" || row.process === "LASER ENGRAVED" || row.process === "REFLECTION LASER" || row.process === "4-COLOR PROCESS") {
        const formData = new FormData();
        formData.append("logoName", row.logoName);

        Swal.fire({
          title: "Rechecking logo",
          text: "Checking the colors of your logo for silk screen...",
          icon: "info",
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          customClass: "overlay-area",
          didOpen: () => {
            Swal.showLoading()
          }
        })

        fetch(`${ApiLinks.ActiveLink()}recheck-logo`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
          },
          body: formData
        })
          .then(res => res.json())
          .then(data => {
            Swal.close();
            let silkScreenLimit = 2
            let productCode = sessionStorage.getItem("productCode");
            if (
              productCode === "DW100" ||
              productCode === "DW101" ||
              productCode === "DW103" ||
              productCode === "DW300" ||
              productCode === "DW301"
            ) {
              silkScreenLimit = 4;
            }
            if (data.colorCount > silkScreenLimit) {
              Swal.fire({
                title: 'Oops! It seems this artwork is too colorful.',
                text: 'For silk screen, please choose artwork with ' + silkScreenLimit + ' colors or less.',
                icon: 'error',
                confirmButtonText: 'OK',
                target: document.getElementById('upload-modal'),
                customClass: {
                  confirmButton: 'upload-confirm'
                }
              })
              row.process = copyProcess;
            }
          })
      }

      return options;
    });
  };
  const addBrandingRow = (event) => {
    event.preventDefault();

    setBrandingOptions((brandingSelection) => {
      const selectedLocations = brandingSelection.map(({ location }) => location)
      const availableLocation = filteredLocationOptions.filter((location) => !selectedLocations.includes(location.toUpperCase()))?.[0].toUpperCase()
      const newBrandingOption = {
        id: Date.now().toString(),
        location: availableLocation.toUpperCase(),
        process: locationsBranding.find(({ location }) => location.toUpperCase() === availableLocation)?.brandings[0].toUpperCase(),
        logoName: "APPLY ART",
        isRectangle: "not"
      };
      return [...brandingSelection, newBrandingOption];
    });
  };

  const removeBrandingRow = (event, index) => {
    let newState = [...brandingOptions];
    newState.splice(index, 1);
    setBrandingOptions(newState);
  };

  const setProductTooltip = (product, i) => {
    // setCurrentProduct(product)
    setOpenTooltip(openTooltip === i ? null : i);
  };
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className, tooltip: { marginLeft: 500 } }}
    />
  ))(({ theme }) => ({
    display: openTooltip === null ? "none" : "block",
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#ffffff",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 500,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
    [`& .${tooltipClasses.arrow}`]: {
      "&:before": {
        border: "1px solid #E6E8ED",
        padding: -5,
      },
      color: theme.palette.common.white,
    },
  }));
  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    border: "solid",
    borderRadius: 20,
    borderColor: "white",
    outline: "none",
    transition: "border .24s ease-in-out",
  };
  const focusedStyle = {
    borderColor: "#2196f3",
  };
  const acceptStyle = {
    borderColor: "#00e676",
  };
  const rejectStyle = {
    borderColor: "#ff1744",
  };
  const StyledDropzone = forwardRef((props, ref) => {
    const [showUpload, setShowUpload] = useState(false);

    const testFunc = () => {
      alert("test!");
    };

    useImperativeHandle(ref, () => ({
      test: () => {
        testFunc();
      },
    }));

    const onDrop = useCallback((acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let binaryStr = reader.result;
          setImgSrc(binaryStr);
        };
        setCurrentFileName(file.name);
      });
    }, []);

    const {
      getRootProps,
      getInputProps,
      isFocused,
      isDragAccept,
      isDragReject,
      acceptedFiles,
    } = useDropzone({
      accept: "image/png, image/jpeg, image/svg+xml",
      maxFiles: 1,
      onDropAccepted: onDrop,
      onFileDialogOpen: () => {
        setShowUpload(false);
        setImgSrc("");
      },
    });

    const files = acceptedFiles.map((file) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isFocused, isDragAccept, isDragReject]
    );

    const handleNext = () => {
      const pattern = /data:image\/(jpeg|svg\+xml|png);/g;
      const matches = imgSrc.match(pattern);
      const matchesString = matches.join(" ");

      if (!imgSrc) {
        Swal.fire({
          title: "We can’t Dec this merch out without artwork!",
          text: "Please upload your artwork and we’ll try again.",
          icon: "error",
          confirmButtonText: "OK",
          target: document.getElementById("upload-modal"),
        });
      } else if (currentProcess === "SILK SCREENED" && matchesString === "data:image/jpeg;") {


        setImgSrc("")
        setShowLoading(false)

        handleCloseModal()
        handleOpenModal(currentIndex, currentProcess)

        Swal.fire({
          title: "Do you want to proceed by clearing the art file?",
          text: "Please upload an PNG or SVG thank you.",
          icon: "error",
          confirmButtonText: "Okay",
          target: document.getElementById("upload-modal"),
        });


      }
      else {

        setShowEdit(true);
      }
    };

    return (
      <div className="container">
        {imgSrc && (
          <div style={{ width: "100%" }}>
            {!showToggle ? (
              <Tooltip
                arrow
                placement="left"
                title="Click to turn the background on"
              >
                <VisibilityOffTwoToneIcon
                  onClick={() => setShowToggle(true)}
                  style={{
                    cursor: "pointer",
                    float: "right",
                    marginTop: "-40px",
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip
                arrow
                placement="left"
                title="Click to turn the background off"
              >
                <VisibilityTwoToneIcon
                  onClick={() => setShowToggle(false)}
                  style={{
                    cursor: "pointer",
                    float: "right",
                    marginTop: "-40px",
                  }}
                />
              </Tooltip>
            )}
          </div>
        )}
        <div {...getRootProps({ style })} style={{}}>
          <input {...getInputProps()} />
          {imgSrc ? (
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "180px",
                display: "flex",
                justifyContent: "center",
                backgroundImage: showToggle ? "url(/pre-background.png)" : "",
                backgroundSize: "contain",
              }}
            >
              {imgSrc && (
                <div style={{ height: "100%" }}>
                  <img id="img-main" src={imgSrc} alt="preview" width={180} />
                </div>
              )}
            </div>
          ) : (
            <Grid
              container
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item xs={5} align="center">
                <PhotoSizeSelectActualOutlinedIcon
                  style={{ fontSize: "7.5rem" }}
                />
                <p className="main-font" style={{ fontSize: "1.5rem" }}>
                  <b>Drag it, drop it, upload it! </b>
                </p>
                <p
                  className="main-font"
                  style={{ fontSize: "1rem", marginTop: -25 }}
                >
                  ...or click to browse file, that works too!
                </p>
                <p className="main-font" style={{ fontSize: '1rem', marginTop: 0, color: 'red', fontStyle: 'italic' }}><b>{currentProcess === "4-COLOR PROCESS" ? "PNG, SVG, JPEG" : "PNG or SVG"}</b> files only please</p>
              </Grid>
            </Grid>
          )}
        </div>
        <ul>{files}</ul>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Grid item xs={4} align="center">
            <Button
              onClick={handleNext}
              style={{
                paddingLeft: 20,
                backgroundColor: "#FFA100",
                color: "white",
                borderRadius: 5,
              }}
            >
              <span className="main-font">Next</span>
              <NavigateNextIcon />
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  });
  function srcToFile(src, fileName, mimeType) {
    return fetch(src)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], fileName, { type: mimeType });
      });
  }
  function webgl_support() {
    try {
      var canvas = document.createElement('canvas');
      return !!window.WebGLRenderingContext &&
        (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'));
    } catch (e) {
      return false;
    }
  }
  function checkIfReloaded(productDetalye) {
    if (typeof productDetalye !== "undefined") {
      if (productDetalye.type === "pen") {
        assignBrandingLocValues("pen", productDetalye.productCode);
        setFilterVal("pen");
        console.log("pen branding set");
      } else if (productDetalye.type === "bag") {
        assignBrandingLocValues("bag", productDetalye.productCode);
        setFilterVal("bag");
        console.log("bag branding set");
      }
      setProductCode(productDetalye.productCode);
      onMenuItemChange(productDetalye);
      if (!run1) {
        sessionStorage.setItem("run1", productDetalye.productCode);
        run1 = sessionStorage.getItem("run1");
        console.log("run1 2 " + run1);
        window.location.reload(false);
        console.log("null value");
      } else if (run1 !== productDetalye.productCode) {
        sessionStorage.setItem("run1", productDetalye.productCode);
        run1 = sessionStorage.getItem("run1");
        console.log("run1 2 " + run1);
        window.location.reload(false);
        console.log("new value");
      }
    }
  }
  const onUpload = () => {
    let dataUrl = canvasPreview(
      imgRef.current,
      previewCanvasRef.current,
      completedCrop,
      scale,
      rotate
    );
    dataUrl.then((data) => {
      srcToFile(data, currentFileName, "image/png").then(function (file) {
        setShowLoading(true);
        let logo = file;

        if (!logo) {
          Swal.fire({
            title: "We can’t Dec this merch out without artwork!",
            text: "Please upload your artwork and we’ll try again.",
            icon: "error",
            confirmButtonText: "OK",
            target: document.getElementById("upload-modal"),
          });
        } else {
          let silkScreenLimit = 2;

          let productCode = sessionStorage.getItem("productCode");

          if (
            productCode === "DW100" ||
            productCode === "DW101" ||
            productCode === "DW103" ||
            productCode === "DW300" ||
            productCode === "DW301"
          ) {
            silkScreenLimit = 4;
          }

          const formData = new FormData();
          formData.append("logo", logo);

          fetch(`${ApiLinks.ActiveLink()}upload`, {
            method: "POST",
            headers: {
              Accept: "application/json",
            },
            body: formData,
          })
            .then((res) => res.json())
            .then((data) => {
              if (sessionStorage.getItem("openedAll") === "true") {
                let newState = [...brandingOptions];
                let detectedSilkError = false;
                brandingOptions.forEach((element, i) => {
                  if (
                    element.process === "SILK SCREENED" &&
                    data.colorCount > silkScreenLimit
                  ) {
                    Swal.fire({
                      title: "Oops! It seems this artwork is too colorful.",
                      text:
                        "If you selected silk screen as one of your methods, please choose artwork with " +
                        silkScreenLimit +
                        " colors or less.",
                      icon: "error",
                      confirmButtonText: "OK",
                      customClass: {
                        confirmButton: "upload-confirm",
                      },
                    });
                    newState[i].logoName = "APPLY ART";
                    newState[i].isRectangle = "not"
                    detectedSilkError = true;
                  }
                });

                setImgSrc("");
                setShowLoading(false);
                handleCloseModal();

                if (isMultiple) {
                  if (detectedSilkError) {
                    newState.forEach((state) => {
                      state.logoName = "APPLY ART";
                      state.isRectangle = "not"
                    });
                  } else {
                    newState.forEach((state) => {
                      state.logoName = data.fileName;
                      state.isRectangle = sessionStorage.getItem("rectangleType");
                    });
                  }
                  setBrandingOptions(newState);
                }

                sessionStorage.setItem("openedAll", false);
                setIsMultiple(false);
              } else {
                if (
                  currentProcess === "SILK SCREENED" &&
                  data.colorCount > silkScreenLimit
                ) {
                  setImgSrc("");
                  setShowLoading(false);
                  handleCloseModal();
                  handleOpenModal(currentIndex, currentProcess);
                  Swal.fire({
                    title: "Oops! It seems this artwork is too colorful.",
                    text:
                      "For silk screen, please choose artwork with " +
                      silkScreenLimit +
                      " colors or less.",
                    icon: "error",
                    confirmButtonText: "OK",
                    target: document.getElementById("upload-modal"),
                    customClass: {
                      confirmButton: "upload-confirm",
                    },
                  });
                } else {
                  let newState = [...brandingOptions];

                  if (isMultiple) {
                    newState.forEach((state) => {
                      state.logoName = data.fileName;
                      state.isRectangle = sessionStorage.getItem("rectangleType");
                    });

                    setBrandingOptions(newState);
                  } else {
                    const currentBranding = newState.find(
                      ({ id }) => id === currentIndex
                    );
                    currentBranding.logoName = data.fileName;
                    currentBranding.isRectangle = sessionStorage.getItem("rectangleType");
                    setBrandingOptions(newState);
                  }
                  handleCloseModal();
                  setShowLoading(false);
                }
              }
            });
        }
      });
    });
  };
  const logoUploadModal = (
    <div
      id="upload-modal"
      style={{
        background: "white",
        maxWidth: 1000,
        maxHeight: 600,
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 20,
        padding: 50,
        marginTop: 40,
        paddingLeft: 50,
        paddingRight: 50,
      }}
    >
      {showEdit && (
        <div>
          <CloseRoundedIcon
            onClick={handleCloseModal}
            style={{ cursor: "pointer", float: "right", marginTop: "-10px" }}
          />
          <ArrowBackRoundedIcon
            onClick={() => {
              setImgSrc("");
              setShowEdit(false);
              setIsLonger(null);
            }}
            style={{ cursor: "pointer", float: "left", marginTop: "-10px" }}
          />
        </div>
      )}

      {!showEdit ? (
        <div
          style={{
            border: "solid",
            borderWidth: 1,
            padding: 20,
            borderRadius: 20,
          }}
        >
          <Grid container spacing={1} align="center" sx={{ paddingBottom: 5 }}>
            <Grid item xs={12}>
              <Typography
                style={{
                  fontSize: "1.5rem",
                  fontWeight: 500,
                  display: "inline",
                  color: "black",
                  textDecoration: "none",
                }}
              >
                {/* <span className="main-font">DRAG & DROP</span> */}
              </Typography>
            </Grid>
            {/* <Grid item xs={4}>
                            <Typography style={{ fontSize: '1.5rem', fontWeight: 500, display: 'inline', color: showMyUploads ? 'black' : '#FFA100', textDecoration: showMyUploads ? 'none' : 'underline' }}>
                                <span className="main-font">MY UPLOADS</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography style={{ fontSize: '1.5rem', fontWeight: 500, display: 'inline', color: showTemplates ? 'black' : '#FFA100', textDecoration: showTemplates ? 'none' : 'underline' }}>
                                <span className="main-font">SPEC-TEMPLATES</span>
                            </Typography>
                        </Grid> */}
          </Grid>
          <StyledDropzone />
        </div>
      ) : (
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div style={{ width: "100%" }}>
            {!showToggle ? (
              <Tooltip
                arrow
                placement="left"
                title="Click to turn the background on"
              >
                <VisibilityOffTwoToneIcon
                  onClick={() => setShowToggle(true)}
                  style={{
                    cursor: "pointer",
                    float: "right",
                    marginTop: "15px",
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip
                arrow
                placement="left"
                title="Click to turn the background off"
              >
                <VisibilityTwoToneIcon
                  onClick={() => setShowToggle(false)}
                  style={{
                    cursor: "pointer",
                    float: "right",
                    marginTop: "15px",
                  }}
                />
              </Tooltip>
            )}
          </div>
          <div style={{ display: "none" }}>
            {completedCrop && (
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: "1px solid black",
                  objectFit: "contain",
                  width: completedCrop.width,
                  height: completedCrop.height,
                }}
              />
            )}
          </div>
          <Grid item xs={12}>
            <Typography
              className="main-font"
              style={{ marginTop: -20, fontSize: "2em", fontWeight: "bold" }}
            >
              Pre-processing Area
            </Typography>
            <span className="main-font">
              For optimal results, please ensure your logo is as large as
              possible while fitting within the square below.
            </span>
          </Grid>
          <Grid item xs={3}>
            <Button
              disabled={scale <= 0.7 ? true : false}
              onClick={() => setScale(scale - 0.1)}
              fullWidth
              style={{
                backgroundColor: scale <= 0.7 ? "#757474" : "#FFA100",
                color: "white",
                borderRadius: 5,
              }}
            >
              <RemoveCircleTwoToneIcon />
              &nbsp;
              <span className="main-font">scale it down</span>&nbsp;
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              onClick={onUpload}
              fullWidth
              style={{
                backgroundColor: "#FFA100",
                color: "white",
                borderRadius: 5,
              }}
            >
              {showLoading ? (
                <CircularProgress style={{ color: "white" }} size={20} />
              ) : (
                <Grid container alignItems="center" justifyContent="center">
                  <span className="main-font">LOOKS GOOD!</span>
                  &nbsp;
                  <SentimentVerySatisfiedTwoToneIcon />
                </Grid>
              )}
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              disabled={scale >= 1.3 ? true : false}
              onClick={() => setScale(scale + 0.1)}
              fullWidth
              style={{
                backgroundColor: scale >= 1.3 ? "#757474" : "#FFA100",
                color: "white",
                borderRadius: 5,
              }}
            >
              <span className="main-font">scale it up</span>&nbsp;
              <AddCircleTwoToneIcon />
            </Button>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 15 }}>
            <ReactCrop
              crop={crop}
              disabled
              style={{ border: "1px dashed #FFA100" }}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={1}
            >
              <div
                style={{
                  height: 400,
                  width: 400,
                  display: "flex",
                  justifyContent: "center",
                  backgroundImage: showToggle ? "url(/pre-background.png)" : "",
                  backgroundSize: "contain",
                }}
              >
                {isLonger ? (
                  <img
                    ref={imgRef}
                    id="img-processed"
                    alt="Logo Here"
                    src={imgSrc}
                    style={{
                      margin: "auto",
                      display: "block",
                      maxWidth: "100%",
                      height: "100%",
                      transform: `scale(${scale}) rotate(${rotate}deg)`,
                    }}
                    onLoad={onImageLoad}
                  />
                ) : (
                  <img
                    ref={imgRef}
                    id="img-processed"
                    alt="Crop me"
                    src={imgSrc}
                    style={{
                      margin: "auto",
                      display: "block",
                      width: "100%",
                      maxHeight: "100%",
                      transform: `scale(${scale}) rotate(${rotate}deg)`,
                    }}
                    onLoad={onImageLoad}
                  />
                )}
              </div>
            </ReactCrop>
          </Grid>
        </Grid>
      )}
    </div>
  );

  useLayoutEffect(() => {
    let WebGLTest = webgl_support();
    console.log("webgl test ", WebGLTest);
    if (WebGLTest === false) {
      window.location.replace('/not-supported');
    }

    if (sessionStorage.getItem("productType")) {
      setFilterVal(sessionStorage.getItem("productType"));
    } else {
      sessionStorage.setItem("productType", "bottle");
      setFilterVal("bottle");
    }
    sessionStorage.setItem("openedAll", false);
    if (sessionStorage.getItem("product")) {
      let product = JSON.parse(sessionStorage.getItem("product"));

      setProductName(product.productName);
      setProductSummary(product.productSummary);
      setColorList(product.colorsAvailable);

      let newState = [];
      product.colorsAvailable.forEach((product) => {
        let temp = {};
        temp["productColor"] = product.code;
        temp["scene"] = (
          <Canvas3D
            id="canvas-scene"
            ref={snapshotRef}
            productCode={sessionStorage.getItem("productCode")}
            productColor={product.code}
          />
        );
        newState.push(temp);
      });
      setSceneList(newState);
      setShowOverlay(true);
      loadBottle();
      setTimeout(() => {
        setShowOverlay(false);
      }, 6000);
    }

    if (typeof sku !== "undefined") {
      let productDetails = productData?.find(
        (product) => product.productCode === sku
      );
      console.log(productDetails);
      switch (productDetails.type) {
        case "pen":
          console.log("pens");
          clearProducts();
          setFilterVal("pen");
          sessionStorage.setItem("productType", "pen");
          assignBrandingLocValues("pen", sku);
          checkIfReloaded(productDetails);

          break;
        case "bag":
          console.log("bags");
          clearProducts();
          setFilterVal("bag");
          sessionStorage.setItem("productType", "bag");
          assignBrandingLocValues("bag", sku);
          checkIfReloaded(productDetails);
          break;
        case "bottle":
        default:
          clearProducts();
          setFilterVal("bottle");
          sessionStorage.setItem("productType", "bottle");
          checkIfReloaded(productDetails);
      }
    }
    if (sessionStorage.getItem("selectedBrandingOptions")) {
      let presets = JSON.parse(
        sessionStorage.getItem("selectedBrandingOptions")
      );
      setBrandingOptions(presets);
    } else {
      let defaultProcess = "";
      let defaultLocation = "";

      setBrandingOptions([
        {
          process: defaultProcess,
          location: defaultLocation,
          logoName: "APPLY ART",
          isRectangle: "not"
        },
      ]);
    }

    if (sessionStorage.getItem("productCode")) {
      setProductCode(sessionStorage.getItem("productCode"));
      setProductBuild(true);
    }

    if (sessionStorage.getItem("productColor")) {
      setProductColor(sessionStorage.getItem("productColor"));
      setBrandSelection(true);
    }
  }, []);
  function Loader() {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "50%",
        }}
      >
        <CircularProgress style={{ color: "#ffbf4f" }} thickness={5} />
      </Box>
    );
  }
  function clearProducts() {
    sessionStorage.clear();
    localStorage.clear();
    setShowOverlay(true);
    setProductBuild(false);
    setBrandSelection(false);
  }
  const assignBrandingLocValues = (type) => { };



  return (
    <>
      <Layout>
        <Grid
          container
          className="home-page"
          direction="row"
          style={{
            paddingTop: "100px",
            // paddingLeft: '120px',
            userSelect: "none",
          }}
          spacing={1}
        >
          {/**HEADER */}

          {/**HEADER */}
          <Grid container item flex={1} direction="column" md={9} lg={9} xl={7}>
            <Card sx={{ border: "none", boxShadow: "none" }}>
              <CardContent>
                <Grid container spacing={1} sx={{ padding: 2 }} alignItems="center">
                  <Grid item xs={4} style={{ marginTop: -15, paddingLeft: 25 }}>
                    <Button
                      onClick={() => {
                        clearProducts();
                        setFilterVal("bottle");
                        sessionStorage.setItem("productType", "bottle");
                      }}
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor:
                          sessionStorage.getItem("productType") === "bottle"
                            ? "#FFA100"
                            : "#757474",
                        marginRight: 10,
                        borderRadius: "50px",
                      }}
                    >
                      Bottles
                    </Button>
                    <Button
                      onClick={() => {
                        clearProducts();
                        setFilterVal("pen");
                        sessionStorage.setItem("productType", "pen");
                        assignBrandingLocValues("pen", productCode);
                      }}
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor:
                          sessionStorage.getItem("productType") === "pen"
                            ? "#FFA100"
                            : "#757474",
                        marginRight: 10,
                        borderRadius: "50px",
                      }}
                    >
                      Pens
                    </Button>
                    <Button
                      onClick={() => {
                        clearProducts();
                        setFilterVal("bag");
                        sessionStorage.setItem("productType", "bag");
                        assignBrandingLocValues("bag", productCode);
                      }}
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor:
                          sessionStorage.getItem("productType") === "bag"
                            ? "#FFA100"
                            : "#757474",
                        borderRadius: "50px",
                      }}
                    >
                      Bags
                    </Button>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: -5, paddingLeft: 25 }}>
                    <Typography className="product-name">
                      <span className="main-font">{productName}</span>
                    </Typography>
                    <Typography className="product-summary">
                      <span className="main-font">{productSummary}</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ paddingLeft: 5 }}>
                    <Grid
                      container
                      spacing={1}
                      sx={{ padding: 2 }}
                      alignItems="center"
                    >
                      <Grid item xs={3}>
                        <Typography className="select-title">
                          <span className="main-font star-marker">* </span>
                          <span className="main-font">Select your product</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={5} style={{ marginLeft: 30 }}>
                        <FormControl
                          variant="outlined"
                          className="form-control-select"
                          focused={false}
                        >
                          <Select
                            size="medium"
                            notched={false}
                            IconComponent={UnfoldMoreIcon}
                            sx={{
                              borderRadius: "15px",
                              paddingLeft: "10px",
                              fontSize: "1.2rem",
                            }}
                            className="select-product-dropdown"
                            onChange={onProductChange}
                            onClose={() => {
                              setSearchText("");
                            }}
                            onMouseEnter={() => setOpenTooltip(null)}
                            value={productCode}
                            MenuProps={{ autoFocus: false }}
                          >
                            <MenuItem
                              selected
                              disabled
                              value={0}
                              style={{ display: "none", color: "lightgray" }}
                            >
                              <em style={{ color: "lightgray" }}>
                                <span className="main-font">
                                  Select product variant
                                </span>
                              </em>
                            </MenuItem>
                            <ListSubheader>
                              <TextField
                                ref={inputRef}
                                style={{ paddingTop: 10, paddingBottom: 5 }}
                                size="small"
                                autoFocus
                                placeholder="Type to search..."
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e) => setSearchText(e.target.value)}
                                onKeyDown={(e) => {
                                  inputRef.current.focus();
                                  if (e.key !== "Escape") {
                                    e.stopPropagation();
                                  }
                                }}
                              />
                            </ListSubheader>
                            {displayedOptions
                              ?.filter((product) =>
                                filterProducts(product, filterVal)
                              )
                              .map((product, i) => {
                                return (
                                  <MenuItem
                                    id={product.productName}
                                    className="main-font"
                                    style={{ marginRight: -50 }}
                                    key={i}
                                    value={product.productCode}
                                    onMouseEnter={() =>
                                      setProductTooltip(product, i)
                                    }
                                    onClick={() => {
                                      onMenuItemChange(product);
                                    }}
                                  >
                                    <HtmlTooltip
                                      title={
                                        <React.Fragment>
                                          <h3 className="main-font">
                                            {product.productName}
                                          </h3>
                                          <img
                                            src={`https://www.spectorandco.com/product_images/products/${product.productImg}`}
                                            width="300"
                                            alt="product"
                                          ></img>
                                        </React.Fragment>
                                      }
                                      placement="right"
                                      arrow
                                    >
                                      <div style={{ width: "100%" }}>
                                        <span className="main-font">
                                          {product.productCode +
                                            " - " +
                                            product.productName}
                                        </span>
                                      </div>
                                    </HtmlTooltip>
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12} style={{ paddingTop: 30, paddingLeft: 10 }}> */}
                  {/* <Typography style={{ fontSize: '1.5rem' }}><span className="main-font"><span style={{ fontWeight: 600 }}>Product Dimensions:</span> {productDimensions}</span></Typography>                       */}
                  {/* </Grid> */}
                  {/* product build selection goes here */}
                  {showProductBuild && (
                    <Grid item xs={12} style={{ paddingTop: 0, paddingLeft: 5 }}>
                      <Grid
                        container
                        spacing={1}
                        sx={{ padding: 2 }}
                        alignItems="center"
                      >
                        <Grid item xs={3}>
                          <Typography className="select-title">
                            <span className="main-font star-marker">* </span>
                            <span className="main-font">Select your color</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={5} style={{ marginLeft: 30 }}>
                          <FormControl
                            variant="outlined"
                            className="form-control-select"
                            focused={false}
                          >
                            <Select
                              size="medium"
                              sx={{
                                borderRadius: "15px",
                                paddingLeft: "10px",
                                fontSize: "1.2rem",
                              }}
                              notched={false}
                              IconComponent={UnfoldMoreIcon}
                              onChange={onProductColorChange}
                              value={productColor}
                            >
                              <MenuItem
                                selected
                                disabled
                                value={defaultColor}
                                style={{ display: "none", color: "lightgray" }}
                              >
                                <em style={{ color: "lightgray" }}>
                                  <span className="main-font">{defaultColor}</span>
                                </em>
                              </MenuItem>
                              {colorList &&
                                colorList.map((color, i) => {
                                  return (
                                    <MenuItem
                                      className="main-font"
                                      key={i}
                                      value={color.code}
                                    >
                                      <span className="main-font">
                                        {color.name}
                                      </span>
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {/* brand selection and logo goes here */}
                  {showBrandSelection && (
                    <div>
                      <Grid item xs={12}>
                        <Modal
                          // hideBackdrop={editModal}
                          open={open}
                          onClose={handleCloseModal}
                          style={{ overflowY: "scroll", marginBottom: "20px" }}
                        >
                          {logoUploadModal}
                        </Modal>
                      </Grid>

                      <Grid item xl={12}>
                        <TableContainer
                          sx={{
                            width: "100%",
                            maxHeight: {
                              xl: "100%",
                              lg: "100%",
                            },
                          }}
                          className="table-area"
                        >
                          {/**Adding Process and Removing */}

                          <BrandingSelection
                            brandingOptions={brandingOptions}
                            productCode={productCode}
                            removeBrandingRow={removeBrandingRow}
                            filteredLocationOptions={filteredLocationOptions}
                            locationsBranding={locationsBranding}
                            handleOpenMultipleModal={handleOpenMultipleModal}
                            handleLocationChange={handleLocationChange}
                            handleOpenModal={handleOpenModal}
                            addBrandingRow={addBrandingRow}
                            products={productData}
                            setBrandingOptions={setBrandingOptions}
                          />
                          {/**Adding Process and Removing */}
                        </TableContainer>
                      </Grid>
                    </div>
                  )}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      height: "100%",
                    }}
                  >
                    {sessionStorage.getItem("product") && (
                      <>
                        <Button
                          disabled={brandingOptions.length < 1 ? true : false}
                          onClick={onClickNext}
                          style={{
                            backgroundColor:
                              brandingOptions.length < 1 ? "#757474" : "#FFA100",
                            color: "white",
                            borderRadius: 20,
                            paddingLeft: 20,
                            paddingTop: 10,
                            paddingBottom: 10
                          }}
                        >
                          <span className="main-font">Dec it out</span>
                          <NavigateNextRoundedIcon />
                        </Button>
                        {/* <Button
                          disabled={brandingOptions.length < 1 ? true : false}
                          onClick={onClickQuoteForm}
                          style={{
                            backgroundColor:
                              brandingOptions.length < 1 ? "#757474" : "#fff",
                          }}
                          className="borders"
                        >
                          <img src={LogoBtn} alt="logo-btn" />
                        </Button> */}
                      </>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {/**THIS IS THE CANVAS AREA */}
          <Grid item md={3} lg={3} xl={5}>
            {productCode !== 0 && !sessionStorage.getItem("productCode") && (
              <div id="canvas-area">
                <Canvas3D
                  ref={snapshotRef}
                  productCode={productCode}
                  productColor={defaultColor}
                />
              </div>
            )}
            {sessionStorage.getItem("productCode") && (
              <div id="canvas-area" onMouseDown={() => setShowPrompt(false)}>
                {sceneList.map((item, i) => {
                  return (
                    <Grid
                      key={i}
                      style={{
                        display:
                          item.productColor === productColor ? "block" : "none",
                      }}
                    >
                      {item.scene}
                      {showPrompt !== false && (
                        <Grid
                          id="prompt-area"
                          className="prompt-area"
                          onMouseDown={() => setShowPrompt(false)}
                          sx={{
                            right: {
                              xl: "18.5%",
                              lg: "9%",
                              md: "8%",
                            },
                            textAlign: "center",
                            color: "white",
                            pointerEvents: "none",
                            cursor: "move",
                            zIndex: 85,
                            position: "absolute",
                            top: "50%",
                          }}
                        >
                          <SwipeLeftTwoToneIcon style={{ fontSize: "5rem" }} />
                          <Typography>click & hold</Typography>
                          <Typography>to rotate</Typography>
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
                {showOverlay !== false && (
                  <div className="white-overlay">{overlayLoader && <Loader />}</div>
                )}
              </div>
            )}
          </Grid>
          {/**THIS IS THE CANVAS AREA */}
        </Grid >
      </Layout>
    </>

  );
};
export default ProductSelection;
