import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { useParams, useLocation } from "react-router-dom";
import specLogo from "../../assets/SpectorLogo.svg";
import Add from "../../assets/addbtn.png";
import Minus from "../../assets/minus.png";
import MinusMobile from "../../assets/minus-mobile.png";
import Close from "../../assets/closebtn.png";
import { Modal } from "@mui/material";
import ThankYou from "./ThankYou";
import ConfirmationDialog from "./ConfirmationDialog";
import countries from "../countries/countries";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


const Quote = () => {
  const [quoteModal, setQuoteModal] = useState(false);
  const [selectedProductInfo, setSelectedProductInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [additionalColors, setAdditionalColors] = useState([]);
  const [sendingBtn, setSendingBtn] = useState(false);
  const [successfullySent, setSuccessfullySent] = useState(false);
  const [country, setCountry] = useState("canada");
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [stateProvince, setStateProvince] = useState("Quebec");
  const [stateProvinceOptions, setStateProvinceOptions] = useState([]);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [checkedBlind, setChecked] = useState(false);
  const [textValue, setTextValue] = useState('');
  const form = useRef();
  const location = useLocation();
  const [isSpecialPath, setIsSpecialPath] = useState(false);
  const { productCode } = useParams();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://decdoutmerch.com/forms-api/products/${productCode}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch product data");
        }
        const productData = await response.json();
        setSelectedProductInfo(productData);
        // Provide default values for locationsBranding if it's null or empty
        if (!productData.locationsBranding || productData.locationsBranding.length === 0) {
          productData.locationsBranding = [
            [
              {
                location: "Default Location",
                brandings: ["Default Branding"],
              },
            ],
          ];
        }
        // Set additional colors if data is available
        if (
          productData &&
          productData.colorsAvailable &&
          productData.locationsBranding &&
          productData.locationsBranding.length > 0 &&
          productData.locationsBranding[0] &&
          productData.locationsBranding[0][0] &&
          productData.locationsBranding[0][0].brandings &&
          productData.locationsBranding[0][0].brandings.length > 0
        ) {
          const defaultColor =
            productData.colorsAvailable && productData.colorsAvailable.length > 0
              ? productData.colorsAvailable[0].name
              : "Default Color";
          let defaultBranding = "Random Branding";

          // Check if the first branding is available for the first location
          if (productData.locationsBranding[0][0].brandings.length > 0) {
            // defaultBranding = productData.locationsBranding[0][0].brandings[0];
            defaultBranding = "Random Branding";
          }

          const defaultQuantity = 1;
          setAdditionalColors([
            {
              color: defaultColor,
              branding: defaultBranding,
              quantity: defaultQuantity,
            },
          ]);
        }

        setIsLoading(false);
        setQuoteModal(true);
      } catch (error) {
        console.error("Error fetching product data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [productCode]);
  useEffect(() => {
    // Calculate total quantity whenever additionalColors changes
    const calcTotalQuantity = () => {
      let total = additionalColors.reduce(
        (acc, color) => acc + color.quantity,
        0
      );
      if (total > 10) {
        Swal.fire({
          title: "",
          text: "Maximum quantity limit exceeded. Please reduce total samples to 10 or fewer.",
          icon: "warning"
        });
        const excess = total - 10;
        const ratio = excess / total;
        const updatedColors = additionalColors.map((color) => {
          const reducedQuantity = Math.max(
            1,
            Math.floor(color.quantity * (1 - ratio))
          );
          return { ...color, quantity: reducedQuantity };
        });
        setAdditionalColors(updatedColors);
        total = 10; // Set total to 10 after adjusting quantities
      }
      setTotalQuantity(total);
    };

    calcTotalQuantity();
  }, [additionalColors]);
  useEffect(() => {
    setStateProvinceOptions(countries[country]);
  }, [country]);
  const updateViewportWidth = () => {
    setViewportWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateViewportWidth);

    return () => {
      window.removeEventListener("resize", updateViewportWidth);
    };
  }, []);
  useEffect(() => {
    // Construct the target path based on the productCode variable
    const targetPath = `/forms/us/quote/${productCode}`;
    setIsSpecialPath(location.pathname === targetPath);
  }, [location, productCode]);


  const handleAddColor = () => {
    const updatedColors = [...additionalColors];
    const updatedLocationsBranding = [...selectedProductInfo.locationsBranding];
    const availableColors = selectedProductInfo.colorsAvailable.filter(
      (color) =>
        !updatedColors.some((addedColor) => addedColor.color === color.name)
    );
    if (availableColors.length > 0) {
      const nextAvailableColorIndex = updatedColors.length;
      const nextColor = availableColors[0].name;

      const totalUsedQuantity = updatedColors.reduce(
        (acc, color) => acc + color.quantity,
        0
      );
      const remainingQuantity = Math.max(0, 10 - totalUsedQuantity); // Ensure non-negative remaining

      const defaultQuantity = Math.min(remainingQuantity, 1); // Set default to remaining or 1

      let defaultBranding = "Random Branding";
      if (
        updatedLocationsBranding &&
        updatedLocationsBranding[nextAvailableColorIndex] &&
        updatedLocationsBranding[nextAvailableColorIndex][0] &&
        updatedLocationsBranding[nextAvailableColorIndex][0].brandings &&
        updatedLocationsBranding[nextAvailableColorIndex][0].brandings.length >
        0
      ) {
        defaultBranding = "Random Branding";
      } else {
        defaultBranding = "Random Branding";
      }

      updatedColors.push({
        color: nextColor,
        branding: defaultBranding,
        quantity: defaultQuantity,
        quantityOptions: Array.from(
          { length: remainingQuantity },
          (_, i) => i + 1
        ), // Include 0 as an option
        onRemove: () => removeColor(nextAvailableColorIndex),
      });
      updatedLocationsBranding.push(
        selectedProductInfo.locationsBranding[nextAvailableColorIndex].map(
          (location) => ({ ...location })
        )
      );
      setAdditionalColors(updatedColors);
      setSelectedProductInfo({
        ...selectedProductInfo,
        locationsBranding: updatedLocationsBranding,
      });
    }
  };
  const handleQuantityChange = (event, index) => {
    let newQuantity = parseInt(event.target.value, 10);
    newQuantity = Math.max(1, Math.min(10, newQuantity));
    const updatedColors = [...additionalColors];
    updatedColors[index] = {
      ...updatedColors[index],
      quantity: newQuantity,
    };
    const totalQuantity = updatedColors.reduce(
      (acc, color) => acc + color.quantity,
      0
    );

    if (totalQuantity > 10) {
      Swal.fire({
        title: "",
        text: "Maximum quantity limit exceeded. Please reduce total samples to 10 or fewer.",
        icon: "warning"
      });
      // Adjust the quantity of the color that caused the limit to be exceeded
      updatedColors[index].quantity = 10 - (totalQuantity - newQuantity);

      // Reset the quantity to 1 if it goes above the limit
      if (updatedColors[index].quantity > 10) {
        updatedColors[index].quantity = 1;
      }
    }

    // Update the additionalColors state with the updatedColors array
    setAdditionalColors(updatedColors);
  };
  const removeColor = (index) => {
    const updatedColors = additionalColors.filter((color, i) => i !== index);
    setAdditionalColors(updatedColors);
  };

  const handleColorChange = (event, index) => {
    const selectedColor = event.target.value;
    const updatedColors = [...additionalColors];
    updatedColors[index].color = selectedColor;
    setAdditionalColors(updatedColors);
    // Check if the selected color already exists
    // const colorExists = updatedColors.some(color => color.color === selectedColor);
    // if (colorExists) {
    //   Swal.fire({
    //     title: "",
    //     text: "The color was selected 2x?",
    //     icon: "warning"
    //   });
    // } else {
    //   updatedColors[index].color = selectedColor;
    //   setAdditionalColors(updatedColors);
    // }
  };

  const handleBrandingChange = (event, index) => {
    const selectedBranding = event.target.value;
    const updatedColors = [...additionalColors];
    if (
      selectedProductInfo &&
      selectedProductInfo.locationsBranding &&
      selectedProductInfo.locationsBranding.length > index &&
      selectedProductInfo.locationsBranding[index] &&
      selectedProductInfo.locationsBranding[index][0] &&
      selectedProductInfo.locationsBranding[index][0].brandings
    ) {
      updatedColors[index].branding = selectedBranding;
      setAdditionalColors(updatedColors);
    }
  };
  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setCountry(selectedCountry);
  };
  const handleStateProvinceChange = (e) => {
    setStateProvince(e.target.value);
  };

  // const handleBrandingHover = (event, index) => {
  //   const selectElement = event.target;
  //   const selectedOption =
  //     selectElement.options[selectElement.selectedIndex].text;
  //   const brandingDisplay = document.getElementById(
  //     `branding-display-${index}`
  //   );
  //   if (brandingDisplay && selectedOption.length > 11) {
  //     brandingDisplay.innerText = selectedOption;
  //     brandingDisplay.style.opacity = "1";
  //     console.log(selectedOption, "YET");
  //   } else if (brandingDisplay) {
  //     brandingDisplay.style.opacity = "0"; // Set opacity to 0 when no option is selected
  //   }
  // };
  const handleColorHover = (event, index) => {
    const selectElement = event.target;
    const selectedOption =
      selectElement.options[selectElement.selectedIndex].text;
    const brandingDisplay = document.getElementById(`color-display-${index}`);
    if (brandingDisplay && selectedOption.length > 11) {
      brandingDisplay.innerText = selectedOption;
      brandingDisplay.style.opacity = "1";
      console.log(selectedOption, "YET");
    } else if (brandingDisplay) {
      brandingDisplay.style.opacity = "0"; // Set opacity to 0 when no option is selected
    }
  };
  const handleCloseQuoteModal = () => {
    setQuoteModal(false);
    setShowConfirmation(true);
    // const redirectToUrl = window.confirm("You're about to leave to Spectorandco.com, would you like to proceed?");
    // if (showConfirmation) {

    //     window.location.href = `https://spectorandco.com/us/spe/product/${productCode}`;
    // } else {
    //     return null; // or any action you want to take if the user chooses not to redirect
    // }
  };
  const handleConfirm = () => {
    console.log("I CONFIRM");
    setQuoteModal(false);
    window.location.href = `https://spectorandco.com/us/spe/product/${productCode}`;
    setShowConfirmation(false);
  };

  const handleCancel = () => {
    console.log("CANCEL");
    setQuoteModal(true);
    setShowConfirmation(false);
    setSuccessfullySent(false);
  };
  const handleBlindChange = (event) => {
    setChecked(event.target.checked);
    console.log(checkedBlind)
  }
  const handleTextAreaChange = (event) => {
    setTextValue(event.target.value);
  };

  // useEffect(() => {
  //   const formData = new FormData(form.current);
  //   const formEntries = Object.fromEntries(formData.entries());
  //   console.log(formEntries, 'YET')

  // }, [])
  const sendEmail = async (e) => {
    e.preventDefault();
    const formElements = form.current.querySelectorAll("input, select");
    let isFormValid = true;

    // Check if any form inputs are empty
    formElements.forEach((element) => {
      const container = element.closest(".position-relative"); // Replace with your actual container class name

      if (!element.value) {
        isFormValid = false;
        container.classList.add("error"); // Add an error class for styling (optional)

        // Create and insert error message element within the container
        const errorMessage = document.createElement("p");
        errorMessage.textContent = `Please fill in the required field: ${element.name}`; // Use name or id for better identification
        errorMessage.classList.add("error-message");
        container.appendChild(errorMessage);
      } else {
        container.classList.remove("error"); // Remove error class and message if field is filled
        container.querySelector(".error-message")?.remove(); // Remove existing error message if present
      }
    });
    if (!isFormValid) {
      return;
    }

    const formData = new FormData(form.current);
    const formEntries = Object.fromEntries(formData.entries());
    const colorsData = additionalColors.map((color, index) => ({
      [`color_${index}`]: color.color,
      [`branding_${index}`]: color.branding,
      [`quantity_${index}`]: color.quantity,
    }));
    // Calculate total quantity
    const totalQuantity = additionalColors.reduce(
      (acc, color) => acc + color.quantity,
      0
    );
    setSendingBtn(true);
    const bodyData = {
      title: `Sample Request for Product ${selectedProductInfo.productCode}`,
      body: `            <!-- [if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]--><!-- [if !mso]><!--><!--<![endif]-->
<style>
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
  }

  a[x-apple-data-detectors] {
    color: inherit !important;
    text-decoration: inherit !important;
  }

  #MessageViewBody a {
    color: inherit;
    text-decoration: none;
  }

  p {
    line-height: inherit;
  }

  .desktop_hide,
  .desktop_hide table {
    mso-hide: all;
    display: none;
    max-height: 0px;
    overflow: hidden;
  }

  .image_block img + div {
    display: none;
  }

  @media (max-width: 620px) {
    .desktop_hide table.icons-inner {
      display: inline-block !important;
    }

    .icons-inner {
      text-align: center;
    }

    .icons-inner td {
      margin: 0 auto;
    }

    .image_block div.fullWidth {
      max-width: 100% !important;
    }

    .mobile_hide {
      display: none;
    }

    .row-content {
      width: 100% !important;
    }

    .stack .column {
      width: 100%;
      display: block;
    }

    .mobile_hide {
      min-height: 0;
      max-height: 0;
      max-width: 0;
      overflow: hidden;
      font-size: 0px;
    }

    .desktop_hide,
    .desktop_hide table {
      display: table !important;
      max-height: none !important;
    }

    .row-4 .column-1 .block-1.heading_block h1,
    .row-4 .column-2 .block-1.heading_block h1 {
      text-align: center !important;
    }
  }
</style>
<table
  class="nl-container"
  style="
    mso-table-lspace: 0pt;
    mso-table-rspace: 0pt;
    background-color: #eaf0f6;
    background-image: none;
    background-position: top left;
    background-size: auto;
    background-repeat: no-repeat;
  "
  role="presentation"
  border="0"
  width="100%"
  cellspacing="0"
  cellpadding="0"
>
  <tbody>
    <tr>
      <td>
        <table
          class="row row-1"
          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
          role="presentation"
          border="0"
          width="100%"
          cellspacing="0"
          cellpadding="0"
          align="center"
        >
          <tbody>
            <tr>
              <td>
                <table
                  class="row-content stack"
                  style="
                    mso-table-lspace: 0pt;
                    mso-table-rspace: 0pt;
                    background-color: #ffffff;
                    border-radius: 0;
                    color: #000000;
                    width: 600px;
                    margin: 0 auto;
                  "
                  role="presentation"
                  border="0"
                  width="600"
                  cellspacing="0"
                  cellpadding="0"
                  align="center"
                >
                  <tbody>
                    <tr>
                      <td
                        class="column column-1"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          font-weight: 400;
                          text-align: left;
                          padding-bottom: 5px;
                          padding-top: 5px;
                          vertical-align: top;
                          border: 0px;
                        "
                        width="100%"
                      >
                        <table
                          class="image_block block-1"
                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
                          role="presentation"
                          border="0"
                          width="100%"
                          cellspacing="0"
                          cellpadding="30"
                        >
                          <tbody>
                            <tr>
                              <td class="pad">
                                <div
                                  class="alignment"
                                  style="line-height: 10px"
                                  align="center"
                                >
                                  <div
                                    class="fullWidth"
                                    style="max-width: 300px"
                                  >
                                    <img
                                      style="
                                        display: block;
                                        height: auto;
                                        border: 0;
                                        width: 100%;
                                      "
                                      src="https://f9fa91eeb7.imgdist.com/pub/bfra/gpv4vw9k/4ib/c3q/c1s/SpectorLog.png"
                                      width="300"
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
         <table
          class="row row-1"
          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
          role="presentation"
          border="0"
          width="100%"
          cellspacing="0"
          cellpadding="0"
          align="center"
        >
          <tbody>
            <tr>
              <td>
                <table
                  class="row-content stack"
                  style="
                    mso-table-lspace: 0pt;
                    mso-table-rspace: 0pt;
                    background-color: #ffffff;
                    border-radius: 0;
                    color: #000000;
                    width: 600px;
                    margin: 0 auto;
                  "
                  role="presentation"
                  border="0"
                  width="600"
                  cellspacing="0"
                  cellpadding="0"
                  align="center"
                >
                  <tbody>
                    <tr>
                      <td
                        class="column column-1"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          font-weight: 400;
                          text-align: left;
                          padding-bottom: 5px;
                          padding-top: 5px;
                          vertical-align: top;
                          border: 0px;
                        "
                        width="100%"
                      >
                        <table
                          class="image_block block-1"
                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
                          role="presentation"
                          border="0"
                          width="100%"
                          cellspacing="0"
                          cellpadding="30"
                        >
                          <tbody>
                            <tr>
                              <td class="pad">
                                <div
                                  class="alignment"
                                  style="line-height: 10px"
                                  align="center"
                                >
                                  <div
                                    class="fullWidth"
                                    style="max-width: 300px"
                                  >
                                    <img
                                      loading="lazy"
                                      alt="ProductImage"
                                      style="
                                        display: block;
                                        height: auto;
                                        border: 0;
                                        width: 100%;
                                      "
                                      src="https://www.spectorandco.com/product_images/products/${selectedProductInfo.productImg}"
                                      width="300"
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          class="row row-3"
          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
          role="presentation"
          border="0"
          width="100%"
          cellspacing="0"
          cellpadding="0"
          align="center"
        >
          <tbody>
            <tr>
              <td>
                <table
                  class="row-content stack"
                  style="
                    mso-table-lspace: 0pt;
                    mso-table-rspace: 0pt;
                    background-color: #ffffff;
                    border-radius: 0;
                    color: #000000;
                    border-top: 0 solid #000000;
                    width: 600px;
                    margin: 0 auto;
                  "
                  role="presentation"
                  border="0"
                  width="600"
                  cellspacing="0"
                  cellpadding="0"
                  align="center"
                >
                  <tbody>
                    <tr>
                      <td
                        class="column column-1"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          font-weight: 400;
                          text-align: left;
                          padding-bottom: 5px;
                          padding-top: 5px;
                          vertical-align: top;
                          border: 0px;
                        "
                        width="100%"
                      >
                        <table
                          class="heading_block block-1"
                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
                          role="presentation"
                          border="0"
                          width="100%"
                          cellspacing="0"
                          cellpadding="10"
                        >
                          <tbody>
                            <tr>
                              <td class="pad">
                                <h1
                                  style="
                                    margin: 0;
                                    color: #000000;
                                    direction: ltr;
                                    font-family: Arial, Helvetica, sans-serif;
                                    font-size: 30px;
                                    font-weight: bold;
                                    letter-spacing: normal;
                                    line-height: 120%;
                                    text-align: center;
                                    margin-top: 0;
                                    margin-bottom: 0;
                                    mso-line-height-alt: 45.6px;
                                  "
                                >
                                  <span class="tinyMce-placeholder"
                                    >Sample Order Confirmation</span
                                  >
                                </h1>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          class="row row-4"
          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
          role="presentation"
          border="0"
          width="100%"
          cellspacing="0"
          cellpadding="0"
          align="center"
        >
          <tbody>
            <tr>
              <td>
                <table
                  class="row-content stack"
                  style="
                    mso-table-lspace: 0pt;
                    mso-table-rspace: 0pt;
                    background-color: #ffffff;
                    border-radius: 0;
                    color: #000000;
                    width: 600px;
                    margin: 0 auto;
                  "
                  role="presentation"
                  border="0"
                  width="600"
                  cellspacing="0"
                  cellpadding="0"
                  align="center"
                >
                  <tbody>
                    <tr>
                      <td
                        class="column column-1"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          font-weight: 400;
                          text-align: left;
                          padding-bottom: 5px;
                          padding-top: 5px;
                          vertical-align: top;
                          border: 0px;
                        "
                        width="50%"
                      >
                        <table
                          class="heading_block block-1"
                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
                          role="presentation"
                          border="0"
                          width="100%"
                          cellspacing="0"
                          cellpadding="10"
                        >
                          <tbody>
                            <tr>
                              <td class="pad">
                                <h1
                                  style="
                                    margin: 0;
                                    color: #000000;
                                    direction: ltr;
                                    font-family: Arial, Helvetica, sans-serif;
                                    font-size: 18px;
                                    font-weight: bold;
                                    letter-spacing: normal;
                                    line-height: 120%;
                                    text-align: center;
                                    margin-top: 0;
                                    margin-bottom: 0;
                                    mso-line-height-alt: 21.599999999999998px;
                                  "
                                >
                                  <span class="tinyMce-placeholder"
                                    >${selectedProductInfo.productName}</span
                                  >
                                </h1>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td
                        class="column column-2"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          font-weight: 400;
                          text-align: left;
                          padding-bottom: 5px;
                          padding-top: 5px;
                          vertical-align: top;
                          border: 0px;
                        "
                        width="50%"
                      >
                        <table
                          class="heading_block block-1"
                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
                          role="presentation"
                          border="0"
                          width="100%"
                          cellspacing="0"
                          cellpadding="10"
                        >
                          <tbody>
                            <tr>
                              <td class="pad">
                                <h1
                                  style="
                                    margin: 0;
                                    color: #000000;
                                    direction: ltr;
                                    font-family: Arial, Helvetica, sans-serif;
                                    font-size: 18px;
                                    font-weight: bold;
                                    letter-spacing: normal;
                                    line-height: 120%;
                                    text-align: center;
                                    margin-top: 0;
                                    margin-bottom: 0;
                                    mso-line-height-alt: 21.599999999999998px;
                                  "
                                >
                                  <span class="tinyMce-placeholder"
                                    >${selectedProductInfo.productCode}</span
                                  >
                                </h1>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          class="row row-5"
          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
          role="presentation"
          border="0"
          width="100%"
          cellspacing="0"
          cellpadding="0"
          align="center"
        >
          <tbody>
            <tr>
              <td>
                <table
                  class="row-content stack"
                  style="
                    mso-table-lspace: 0pt;
                    mso-table-rspace: 0pt;
                    background-color: #ffffff;
                    border-radius: 0;
                    color: #000000;
                    width: 600px;
                    margin: 0 auto;
                  "
                  role="presentation"
                  border="0"
                  width="600"
                  cellspacing="0"
                  cellpadding="0"
                  align="center"
                >
                  <tbody>
                    <tr>
                      <td
                        class="column column-1"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          font-weight: 400;
                          text-align: left;
                          padding-bottom: 5px;
                          padding-top: 5px;
                          vertical-align: top;
                          border: 0px;
                        "
                        width="33.333333333333336%"
                      >
                        <table
                          class="paragraph_block block-1"
                          style="
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            word-break: break-word;
                          "
                          role="presentation"
                          border="0"
                          width="100%"
                          cellspacing="0"
                          cellpadding="10"
                        >
                          <tbody>
                            <tr>
                              <td class="pad">
                                <div
                                  style="
                                    color: #101112;
                                    direction: ltr;
                                    font-family: Arial, Helvetica, sans-serif;
                                    font-size: 16px;
                                    font-weight: 400;
                                    letter-spacing: 0px;
                                    line-height: 120%;
                                    text-align: center;
                                    mso-line-height-alt: 19.2px;
                                  "
                                >
                                  <p style="margin: 0; margin-bottom: 16px">
                                    Color
                                  </p>
                                     ${additionalColors
          .map(
            (color, index) =>
              `<p style="margin: 0; margin-bottom: 16px"><strong>${color.color}</p>`
          )
          .join("")}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td
                        class="column column-2"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          font-weight: 400;
                          text-align: left;
                          padding-bottom: 5px;
                          padding-top: 5px;
                          vertical-align: top;
                          border: 0px;
                        "
                        width="33.333333333333336%"
                      >
                        <table
                          class="paragraph_block block-1"
                          style="
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            word-break: break-word;
                          "
                          role="presentation"
                          border="0"
                          width="100%"
                          cellspacing="0"
                          cellpadding="10"
                        >
                          <tbody>
                            <tr>
                              <td class="pad">
                                <div
                                  style="
                                    color: #101112;
                                    direction: ltr;
                                    font-family: Arial, Helvetica, sans-serif;
                                    font-size: 16px;
                                    font-weight: 400;
                                    letter-spacing: 0px;
                                    line-height: 120%;
                                    text-align: center;
                                    mso-line-height-alt: 19.2px;
                                  "
                                >
                                  <p style="margin: 0; margin-bottom: 16px">
                                    Decoration
                                  </p>
                                                           ${additionalColors
          .map(
            (color, index) =>
              `<p style="margin: 0; margin-bottom: 16px"><strong>${color.branding}</p>`
          )
          .join("")}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td
                        class="column column-3"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          font-weight: 400;
                          text-align: left;
                          padding-bottom: 5px;
                          padding-top: 5px;
                          vertical-align: top;
                          border: 0px;
                        "
                        width="33.333333333333336%"
                      >
                        <table
                          class="paragraph_block block-1"
                          style="
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            word-break: break-word;
                          "
                          role="presentation"
                          border="0"
                          width="100%"
                          cellspacing="0"
                          cellpadding="10"
                        >
                          <tbody>
                            <tr>
                              <td class="pad">
                                <div
                                  style="
                                    color: #101112;
                                    direction: ltr;
                                    font-family: Arial, Helvetica, sans-serif;
                                    font-size: 16px;
                                    font-weight: 400;
                                    letter-spacing: 0px;
                                    line-height: 120%;
                                    text-align: center;
                                    mso-line-height-alt: 19.2px;
                                  "
                                >
                                  <p style="margin: 0; margin-bottom: 16px">
                                    Quantity
                                  </p>
                                                           ${additionalColors
          .map(
            (color, index) =>
              `<p style="margin: 0; margin-bottom: 16px"><strong>${color.quantity}</p>`
          )
          .join("")}
                                 
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          class="row row-6"
          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
          role="presentation"
          border="0"
          width="100%"
          cellspacing="0"
          cellpadding="0"
          align="center"
        >
          <tbody>
            <tr>
              <td>
                <table
                  class="row-content stack"
                  style="
                    mso-table-lspace: 0pt;
                    mso-table-rspace: 0pt;
                    border-radius: 0;
                    color: #000000;
                    width: 600px;
                    margin: 0 auto;
                    background-color: #ffffff;
                  "
                  role="presentation"
                  border="0"
                  width="600"
                  cellspacing="0"
                  cellpadding="0"
                  align="center"
                >
                  <tbody>
                    <tr>
                      <td
                        class="column column-1"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          font-weight: 400;
                          text-align: left;
                          padding-bottom: 5px;
                          padding-top: 5px;
                          vertical-align: top;
                          border: 0px;
                        "
                        width="50%"
                      >
                        <table
                          class="paragraph_block block-1"
                          style="
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            word-break: break-word;
                          "
                          role="presentation"
                          border="0"
                          width="100%"
                          cellspacing="0"
                          cellpadding="10"
                        >
                          <tbody>
                            <tr>
                              <td class="pad">
                                <div
                                  style="
                                    color: #101112;
                                    direction: ltr;
                                    font-family: Arial, Helvetica, sans-serif;
                                    font-size: 16px;
                                    font-weight: 400;
                                    letter-spacing: 0px;
                                    line-height: 120%;
                                    text-align: left;
                                    mso-line-height-alt: 19.2px;
                                  "
                                >
                                  <p style="margin: 0; margin-bottom: 16px">
                                    Customer Email: <span style=" font-weight: bold;">${formEntries.Email}</span>
                                  </p>
                                   <p style="margin: 0; margin-bottom: 16px">
                                    Company Name: <span style=" font-weight: bold;">${formEntries.CompanyName}</span>
                                  </p>
                                  <p style="margin: 0; margin-bottom: 16px">
                                    Customer #: <span style=" font-weight: bold;">${formEntries.CustomerNumber}</span>
                                  </p>
                                  <p style="margin: 0; margin-bottom: 16px">
                                    Recipient Name: <span style=" font-weight: bold;">${formEntries.Recipient}</span>
                                  </p>
                                   <p style="margin: 0">
                                    PO #: <span style=" font-weight: bold;">${formEntries.Pobox}</span>
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td
                        class="column column-2"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          font-weight: 400;
                          text-align: left;
                          padding-bottom: 5px;
                          padding-top: 5px;
                          vertical-align: top;
                          border: 0px;
                        "
                        width="50%"
                      >
                        <table
                          class="paragraph_block block-1"
                          style="
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            word-break: break-word;
                          "
                          role="presentation"
                          border="0"
                          width="100%"
                          cellspacing="0"
                          cellpadding="10"
                        >
                          <tbody>
                            <tr>
                              <td class="pad">
                                <div
                                  style="
                                    color: #000000;
                                    direction: ltr;
                                    font-family: Arial, Helvetica, sans-serif;
                                    font-size: 16px;
                                    font-weight: 400;
                                    letter-spacing: 0px;
                                    line-height: 120%;
                                    text-align: left;
                                    mso-line-height-alt: 19.2px;
                                  "
                                >
                                   <p style="color: #000000">
                                    Shipping address: <span style=" font-weight: bold;">${formEntries.Address}</span>
                                  </p>
                                   <p style="color: #000000">
                                    City: <span style=" font-weight: bold;">${formEntries.City}</span>
                                  </p>
                                   <p style="color: #000000">
                                    State: <span style=" font-weight: bold;">${stateProvince}</span>
                                  </p>
                                 <p style="color: #000000">
                                    Country: <span style=" font-weight: bold;">${country.toUpperCase()}</span>
                                  </p>
                                  <p style="color: #000000">Zip: <span style=" font-weight: bold;">${formEntries.Zip}</span></p>
                                  <p style="color: #000000">
                                    Shipper Number: <span style=" font-weight: bold;">${formEntries.ShipperNumber || "N/A"}</span>
                                  </p>
                                    <p style="color: #000000">
                                    Blind Ship: <span style=" font-weight: bold;">${checkedBlind ? "Yes" : "No"}</span>
                                  </p>
                                  
                                   <p style="color: #000000">
                                    Notes: <span style=" font-weight: bold;">${textValue}</span>
                                  </p>
                                  
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          class="row row-6"
          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
          role="presentation"
          border="0"
          width="100%"
          cellspacing="0"
          cellpadding="0"
          align="center"
        >
          <tbody>
            <tr>
              <td>
                <table
                  class="row-content stack"
                  style="
                    mso-table-lspace: 0pt;
                    mso-table-rspace: 0pt;
                    border-radius: 0;
                    color: #000000;
                    width: 600px;
                    margin: 0 auto;
                    background-color: #ffffff;
                  "
                  role="presentation"
                  border="0"
                  width="600"
                  cellspacing="0"
                  cellpadding="0"
                  align="center"
                >
                  <tbody>
                    <tr>
                      <td
                        class="column column-1"
                        style="
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                          font-weight: 400;
                          text-align: left;
                          padding-bottom: 5px;
                          padding-top: 5px;
                          vertical-align: top;
                          border: 0px;
                        "
                        width="100%"
                      >
                        <table
                          class="paragraph_block block-1"
                          style="
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                            word-break: break-word;
                          "
                          role="presentation"
                          border="0"
                          width="100%"
                          cellspacing="0"
                          cellpadding="10"
                        >
                          <tbody>
                            <tr>
                              <td class="pad">
                                <div
                                  style="
                                    color: #101112;
                                    direction: ltr;
                                    font-family: Arial, Helvetica, sans-serif;
                                    font-size: 16px;
                                    font-weight: 400;
                                    letter-spacing: 0px;
                                    line-height: 120%;
                                    text-align: left;
                                    mso-line-height-alt: 19.2px;
                                  "
                                >
                                  <p style="margin: 0; display: none">
                                    Found a mistake with your order? Click here
                                    to cancel and restart your sample order.
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>
`,
    };
    try {
      const response = await fetch(
        "https://decdoutmerch.com/forms-api/mail/send",
        {
          method: "POST",
          headers: {
            "Content-Type": "text/json",
          },
          body: JSON.stringify(bodyData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send email");
      }
      console.log("Email sent successfully");
      form.current.reset();
      setSuccessfullySent(true);
    } catch (error) {
      alert("Email sending failed:", error.message);
    } finally {
      setSendingBtn(false);
    }
  };

  return (
    <>
      <section className={isSpecialPath ? 'special-root' : 'default-root'}>
        <div className="header-quote">
          <img src={specLogo} alt="spector-logo" />
        </div>
        <div className="container">
          {successfullySent ? (
            <>
              <ThankYou
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                french={false}
                title={"Thank You!"}
                messageOne={"Your sample request has been sent successfully."}
                redirectMess={" Your email has been sent successfully. Redirecting to spectorandco.com in"} />
            </>
          ) : (
            <Modal
              open={quoteModal}
              onClose={handleCloseQuoteModal}
              className="quoteform-modal"
            >
              <div className="modal-container">
                <div className="position-absolute overflow-hidden circle-container">
                  <div className="circle-one"></div>
                  <div className="circle-two"></div>
                  <div className="close-btn" onClick={handleCloseQuoteModal}>
                    <img
                      src={Close}
                      className="text-white"
                      width={25}
                      alt="Close"
                    />
                  </div>
                </div>
                <div className="lg grid-2">
                  <div className="form-container-modal">
                    {isLoading ? (
                      <p>Loading...</p>
                    ) : selectedProductInfo ? (
                      <>
                        <div className="card-container">
                          <div className="cards">
                            <img
                              src={`https://www.spectorandco.com/product_images/products/${selectedProductInfo?.productImg}`}
                              alt="product-quote"
                              loading="lazy"
                            />
                            <p className="text-center">
                              {selectedProductInfo.productCode}
                            </p>
                            <h5 className="text-center bold product-name-quote">
                              {selectedProductInfo.productName}
                            </h5>
                            <p className="text-center product-summary-quote">
                              {selectedProductInfo.productSummary}
                            </p>
                          </div>
                        </div>

                        {viewportWidth < 1025 ? (
                          <>
                            <div className="mt-4">
                              {selectedProductInfo &&
                                additionalColors.map((color, index) => (
                                  <div
                                    key={index}
                                    className="border rounded p-3 gap-2 mb-4 position-relative"
                                  >
                                    {index > 0 && (
                                      <div
                                        onClick={() => removeColor(index)}
                                        className="btn-minus mobile w-full d-flex justify-content-end position-absolute"
                                      >
                                        <img
                                          src={MinusMobile}
                                          alt="remove color"
                                        />
                                      </div>
                                    )}
                                    <div className="d-flex gap-1 align-items-center mb-2">
                                      <label className="mb-2 flex-1">
                                        Quantity:
                                      </label>
                                      <select
                                        className="form-select flex-1"
                                        value={color.quantity}
                                        onChange={(e) =>
                                          handleQuantityChange(e, index)
                                        }
                                      >
                                        {/* Default options initially (1 to 10) */}
                                        {color.quantityOptions === undefined
                                          ? Array.from(
                                            { length: 10 },
                                            (_, i) => i + 1
                                          ).map((quantity) => (
                                            <option
                                              key={quantity}
                                              value={quantity}
                                            >
                                              {quantity}
                                            </option>
                                          ))
                                          : color.quantityOptions.map(
                                            (quantity) => (
                                              <option
                                                key={quantity}
                                                value={quantity}
                                              >
                                                {quantity}
                                              </option>
                                            )
                                          )}
                                      </select>
                                    </div>
                                    <div className="position-relative d-flex gap-1 align-items-center mb-2">
                                      <label className="mb-2 flex-1">
                                        Color:{" "}
                                      </label>
                                      <select
                                        className="form-select flex-1"
                                        value={color.color}
                                        onChange={(e) =>
                                          handleColorChange(e, index)
                                        }
                                        onMouseOver={(e) =>
                                          handleColorHover(e, index)
                                        }
                                        onMouseOut={() =>
                                        (document.getElementById(
                                          `color-display-${index}`
                                        ).innerText = "")
                                        }
                                      >
                                        {selectedProductInfo.colorsAvailable.map(
                                          (availableColor, i) => (
                                            <option
                                              key={i}
                                              value={availableColor.name}
                                            >
                                              {availableColor.name}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      {/* Display current branding on hover */}
                                      <span
                                        id={`color-display-${index}`}
                                        className="color-display"
                                      ></span>
                                    </div>
                                    <div className="position-relative d-flex gap-1 align-items-center">
                                      <label className="mb-2 flex-1">
                                        <span className="asterisk">*</span> Decoration:
                                      </label>
                                      <select
                                        className="form-select flex-1"
                                        value={
                                          color.branding ||
                                          selectedProductInfo.defaultBranding
                                        }
                                        onChange={(e) =>
                                          handleBrandingChange(e, index)
                                        }
                                      // onMouseOver={(e) =>
                                      //   handleBrandingHover(e, index)
                                      // }
                                      // onMouseOut={() =>
                                      // (document.getElementById(
                                      //   `branding-display-${index}`
                                      // ).innerText = "")
                                      // } 
                                      >
                                        <option value="Random Branding">Random Branding</option>
                                        <option value="Blank">Blank</option>
                                      </select>
                                      {/* Display current branding on hover */}
                                      <span
                                        id={`branding-display-${index}`}
                                        className="branding-display"
                                      ></span>
                                    </div>
                                  </div>
                                ))}
                              {additionalColors.length <
                                selectedProductInfo.colorsAvailable.length && (
                                  <>
                                    <div
                                      onClick={handleAddColor}
                                      className="mt-4 d-flex align-items-center"
                                    >
                                      <span className="btn-yellow">
                                        <img src={Add} width={25} alt="Add" />
                                      </span>
                                      Add another color
                                    </div>
                                  </>
                                )}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="overflow-min-height">
                              {selectedProductInfo &&
                                additionalColors.map((color, index) => (
                                  <div
                                    key={index}
                                    className="grid grid-cols-3 gap-3 mb-3"
                                  >
                                    {index > 0 && (
                                      <div
                                        onClick={() => removeColor(index)}
                                        className="btn-minus"
                                      >
                                        <img src={Minus} alt="remove color" />
                                      </div>
                                    )}
                                    <div
                                      className={`${index === 0 ? "space-left" : ""
                                        }`}
                                    >
                                      {index === 0 && (
                                        <label className="mb-2">Quantity</label>
                                      )}
                                      <select
                                        className={`${index === 0 ? "space-left sm" : ""
                                          } form-select`}
                                        value={color.quantity}
                                        onChange={(e) =>
                                          handleQuantityChange(e, index)
                                        }
                                      >
                                        {/* Default options initially (1 to 10) */}
                                        {color.quantityOptions === undefined
                                          ? Array.from(
                                            { length: 10 },
                                            (_, i) => i + 1
                                          ).map((quantity) => (
                                            <option
                                              key={quantity}
                                              value={quantity}
                                            >
                                              {quantity}
                                            </option>
                                          ))
                                          : color.quantityOptions.map(
                                            (quantity) => (
                                              <option
                                                key={quantity}
                                                value={quantity}
                                              >
                                                {quantity}
                                              </option>
                                            )
                                          )}
                                      </select>
                                    </div>
                                    <div className="position-relative">
                                      {index === 0 && (
                                        <label className="mb-2">Color</label>
                                      )}
                                      <select
                                        className="form-select"
                                        value={color.color}
                                        onChange={(e) =>
                                          handleColorChange(e, index)
                                        }
                                        onMouseOver={(e) =>
                                          handleColorHover(e, index)
                                        }
                                        onMouseOut={() =>
                                        (document.getElementById(
                                          `color-display-${index}`
                                        ).innerText = "")
                                        }
                                      >
                                        {selectedProductInfo.colorsAvailable.map(
                                          (availableColor, i) => (
                                            <option
                                              key={i}
                                              value={availableColor.name}
                                            >
                                              {availableColor.name}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      {/* Display current branding on hover */}
                                      <span
                                        id={`color-display-${index}`}
                                        className="color-display"
                                      ></span>
                                    </div>
                                    <div className="position-relative">
                                      {index === 0 && (
                                        <label className="mb-2">
                                          <span className="asterisk">*</span> Decoration
                                        </label>
                                      )}

                                      <select
                                        className="form-select"
                                        value={
                                          color.branding ||
                                          selectedProductInfo.defaultBranding
                                        }
                                        onChange={(e) =>
                                          handleBrandingChange(e, index)
                                        }
                                      // onMouseOver={(e) =>
                                      //   handleBrandingHover(e, index)
                                      // }
                                      // onMouseOut={() =>
                                      // (document.getElementById(
                                      //   `branding-display-${index}`
                                      // ).innerText = "")
                                      // } 
                                      >
                                        <option value="Random Branding">Random Branding</option>
                                        <option value="Blank">Blank</option>
                                      </select>
                                      {/* Display current branding on hover */}
                                      <span
                                        id={`branding-display-${index}`}
                                        className="branding-display"
                                      ></span>
                                    </div>
                                  </div>
                                ))}
                              {additionalColors.length <
                                selectedProductInfo.colorsAvailable.length && (
                                  <>
                                    <div
                                      onClick={handleAddColor}
                                      className="mt-4 d-flex align-items-center cursor-pointer"
                                    >
                                      <span className="btn-yellow">
                                        <img src={Add} width={25} alt="Add" />
                                      </span>
                                      Add another color
                                    </div>
                                  </>
                                )}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <p>No product data found in Database.</p>
                    )}
                  </div>
                  <div className="form-container-modal first">
                    <h1 className="bold">SAMPLE ORDER FORM</h1>
                    <form ref={form}>
                      <div className="mb-3">
                        <h4>CUSTOMER INFO</h4>
                        <div className="row align-items-center position-relative mb-3">
                          <div className="col">
                            <label htmlFor="Email" className="form-label">
                              <span className="asterisk">*</span> Email:
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="Email"
                              name="Email"
                              required
                            />
                          </div>
                          <div className="col">
                            <label
                              htmlFor="CustomerNumber"
                              className="form-label"
                            >
                              <span className="asterisk">*</span> Customer #:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="CustomerNumber"
                              name="CustomerNumber"
                              required
                            />
                          </div>
                        </div>
                        <div className="mb-3 position-relative">
                          <label htmlFor="Recipient" className="form-label">
                            <span className="asterisk">*</span> Company Name:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="CompanyName"
                            name="CompanyName"
                          />
                        </div>
                      </div>
                      <div className="mb-3 position-relative">
                        <h4>SHIPPING INFO</h4>
                        <label htmlFor="Pobox" className="form-label">
                          <span className="asterisk">*</span> PO #:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="Pobox"
                          name="Pobox"
                        />
                      </div>
                      <div className="mb-3 position-relative">
                        <label htmlFor="Recipient" className="form-label">
                          <span className="asterisk">*</span> Recipient Name:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="Recipient"
                          name="Recipient"
                        />
                      </div>
                      <div className="mb-3 position-relative">
                        <label htmlFor="Address" className="form-label">
                          <span className="asterisk">*</span> Address:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="Address"
                          name="Address"
                        />
                      </div>
                      <div className="mb-3 position-relative">
                        <label htmlFor="City" className="form-label">
                          <span className="asterisk">*</span> City:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="City"
                          name="City"
                        />
                      </div>
                      <div className="row align-items-center position-relative">
                        <div className="col mb-3">
                          <label className="form-label">
                            <span className="asterisk">*</span> Country
                          </label>
                          <select
                            className="form-select"
                            value={country}
                            onChange={(e) => handleCountryChange(e)}
                          >
                            <option value="canada">Canada</option>
                            <option value="usa">USA</option>
                          </select>
                        </div>
                        <div className="col mb-3">
                          <label htmlFor="state" className="form-label">
                            <span className="asterisk">*</span> State/Province:
                          </label>
                          <select
                            className="form-select"
                            id="state"
                            name="state"
                            value={stateProvince}
                            onChange={handleStateProvinceChange}
                          >
                            <option value="">Select State/Province</option>
                            {stateProvinceOptions.map(
                              (stateProvince, index) => (
                                <option key={index} value={stateProvince}>
                                  {stateProvince}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="row align-items-end position-relative mb-3">
                        <div className="col">
                          <label htmlFor="Zip" className="form-label">
                            <span className="asterisk">*</span> Zip/Postal Code:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="Zip"
                            name="Zip"
                          />
                        </div>
                        <div className="col ">
                          <label htmlFor="ShipperNumber" className="form-label">
                            Shipper #:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="ShipperNumber"
                            name="ShipperNumber"
                          />
                        </div>
                        <div className="col">
                          <FormGroup>
                            <FormControlLabel onChange={handleBlindChange} control={<Checkbox />} label="Blind Ship" />
                          </FormGroup>
                        </div>
                      </div>
                      <div className="mb-3 position-relative">
                        <textarea
                          value={textValue}
                          onChange={handleTextAreaChange}
                          rows={3}
                          cols={20}
                          placeholder="Notes"
                          className="textareafield"
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div>
                  <div className="disclaimer">
                    <p>
                      <span className="asterisk">*</span> Please Note: Branded samples come decorated with pre-existing logos and do not necessarily reflect the source from which you accessed this form.
                    </p>
                  </div>
                  <div className="mb-3 mt-3 disclaimer" >
                    <p>
                      <span className="asterisk">*</span> Don’t have a
                      Spector Customer number?{" "}
                      <a
                        rel="noreferrer"
                        href="https://promotions.spectorandco.com/distformeng"
                        target="_blank"
                      >
                        Please click here
                      </a>{" "}
                      to register.
                    </p>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      type="button"
                      className={`send-btn ${sendingBtn ? "disable" : ""
                        } btn bg-yellow`}
                      disabled={totalQuantity > 10}
                      onClick={sendEmail}
                    >
                      {sendingBtn
                        ? "Email Sending . . ."
                        : "PLACE SAMPLE REQUEST"}
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          )}
          {showConfirmation && (
            <ConfirmationDialog
              no={"No"}
              yes={"Yes"}
              message="You're about to leave to Spectorandco.com, would you like to proceed?"
              onConfirm={handleConfirm}
              onCancel={handleCancel}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default Quote;
